.drawingArea
    border: 1px solid lightgrey
    background-color: white

.canvasContainer
    border-top: 1px solid lightgrey
    overflow: hidden
    position: relative

canvas
    //position: absolute
