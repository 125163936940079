$primary-color-darkest: #000509
$primary-color-dark: #33638F
$primary-color: #649aaa
$primary-color-light: #33638F
$primary-color-lightest: #8CADCB

$secondary-color-darkest: #444444
$secondary-color-dark: #666666
$secondary-color: #576071
$secondary-color-light: #BBBBBB
$secondary-color-lightest: #DDDDDD

$ternary-color-darkest: #000906
$ternary-color-dark: #00452B
$ternary-color: #0A724B
$ternary-color-light: #2D956E
$ternary-color-lightest: #8ACFB5

$contrast-color-darkest: #00000A
$contrast-color-dark: #090748
$contrast-color: #775DD0
$contrast-color-light: #423E9A
$contrast-color-lightest: #9996D2

$darkgreen: #006400
$red: #FF0000
$darkred: #8B0000
$amber: #FFBF00
$blue: #0000FF

$screen-md-min: 600px
$screen-lg-min: 1200px

.website
    width: 100%
    max-width: 100vw
    overflow: hidden
    display: flex
    flex-direction: column
    text-align: start
    box-sizing: border-box

    .hideOnSmall
        display: none

    .body

    .content
        box-sizing: border-box
        width: 100%
        max-width: 100vw
        margin-top: 80px
        padding-left: 15px
        padding-right: 15px
        //border: 1px solid red

    .header
        box-sizing: border-box
        width: 100%
        max-width: 100vw
        position: fixed
        z-index: 100
        top: 0
        left: 0
        right: 0
        background: rgba(255, 255, 255, 0.9)
        padding: 15px
        display: flex
        align-items: center

    .footer
        box-sizing: border-box
        width: 100%
        max-width: 100vw
        padding-left: 15px
        padding-right: 15px
        height: 400px

    .flow
        display: block

    .block33: last-of-type
        margin-bottom: 0px

    .block33
        width: 100%
        margin-bottom: 20px

    .block40
        width: 100%
        margin-bottom: 20px

    .block40
        width: 100%
        margin-bottom: 20px

    .block50: last-of-type
        width: 100%
        margin-bottom: 0px

    .block60
        width: 100%
        margin-bottom: 20px

    .block60: last-of-type
        width: 100%
        margin-bottom: 0px

    .block66
        width: 100%
        margin-bottom: 20px

    .block66: last-of-type
        width: 100%
        margin-bottom: 0px

    .height600
        height: 600px

    .zIndex999
        z-index: 999

    .img100Pct
        width: 99%
        height: auto

    .img60Pct
        width: 60%
        height: auto

    .imgBorder
        border: 1px solid #E1E1E1

    .centeredContentSection
        display: flex
        flex-direction: column
        align-items: center

    .marginTopS
        margin-top: 10px

    .marginTopM
        margin-top: 16px

    .marginTopL
        margin-top: 20px

    .marginTopXL
        margin-top: 25px

    .marginTopXXL
        margin-top: 30px

    .marginBottomXXL
        margin-top: 30px

    .titleText
        font-size: 30px
        font-weight: bold

    .headerText
        font-size: 18px
        font-weight: bold

    .subHeaderText
        font-size: 16px
        font-weight: bold

    .largeBodyText
        font-size: 18px

    .bodyText
        font-size: 14px

    .smallBodyText
        font-size: 12px

    .textAlignCenter
        text-align: center

    .storeBadgeContainer
        display: flex
        justifyContent: center
        gap: 20px

    .storeBadge
        height: 40px
        border: none

    .socialMediaLogo
        width: 16px
        height: 16px

    .App-logo
        height: 40vmin
        pointer-events: none

    .objectPictures
        margin-top: 50px
        display: flex
        gap: 15px

        .objectDetails
            width: 33.3%
            display: flex
            flex-direction: column
            align-items: center

        .objectPicture
            width: 100%
            height: auto

    .onboarding
        padding: 10px
        width: 80%
        font-size: 20px
        color: white
        background-color: $primary-color

    .sectionTitle
        padding-top: 30px
        width: 600px
        font-size: 45px
        font-weight: 600,

    .customerLogo
        height: 40px

    .logoImage
        width: 50px
        height: 50px

    .headerTabs
        display: flex
        align-items: center
        justify-content: center

        .headerTab
            width: 100px
            height: 60px
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            border-bottom: 1px solid #eeeeee
            font-size: 16px

        .headerTab:hover
            background-color: #efefef
            border-bottom: 1px solid #dddddd

        .selected
            border-bottom: 2px solid $primary-color

    .quotePhoto
        width: 100%
        display: flex
        flex-direction: column
        align-items: center

    .quote
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        padding: 15px

        .text
            text-align: center
            font-size: 16px
            font-style: italic

        .author
            color: $primary-color
            margin-top: 20px
            text-align: center
            font-weight: bold
            font-size: 16px

        .role
            color: $primary-color
            text-align: center
            font-size: 16px

    // Company panel

    .person
        width: 300px
        display: flex
        flex-direction: column
        align-items: center

        img
            width: 200px
            height: 200px

        .vita
            height: 70px

        .socialMediaLogo
            width: 16px
            height: 16px

    .logo
        margin-top: 100px
        display: flex
        align-items: center
        justify-content: center

    .wave
        //background: rgb(255 255 255 / 60%)
        //background: rgba(0, 40, 142, 0.03)
        background: rgba(100, 154, 170, 0.05)
        border-radius: 1000% 1000% 0 0
        position: fixed
        width: 200%
        height: 450px
        animation: wave 10s -3s linear infinite
        transform: translate3d(0, 0, 0)
        opacity: 0.8
        bottom: 0
        left: 0
        z-index: 0

        &:nth-of-type(2)
            bottom: -1.25em
            animation: wave 18s linear reverse infinite
            opacity: 0.8

        &:nth-of-type(3)
            bottom: -2.5em
            animation: wave 20s -1s reverse infinite
            opacity: 0.9

    @keyframes wave
        2%
            transform: translateX(1)

        25%
            transform: translateX(-25%)

        50%
            transform: translateX(-50%)

        75%
            transform: translateX(-25%)

        100%
            transform: translateX(1)

    @keyframes rollout
        0%
    //transform: translateY(-200px)
            transform: scale(0%)

        100%
            transform: none

    .rollOut
        animation: rollout 1s

    @media (min-width: $screen-md-min)

        .hideOnSmall
            display: none

        .header
            padding: 20px

        .body
            width: 100vw
            max-width: 100vw
            display: flex
            flex-direction: column
            align-items: center

        .content
            margin-top: 100px
            width: 600px
            max-width: 600px
            padding-left: 0px
            padding-right: 0px
            border: none

        .footer
            width: 600px
            max-width: 600px
            padding-left: 0px
            padding-right: 0px
            height: 400px

        .logoImage
            width: 60px
            height: 60px

        .marginTopS
            margin-top: 10px

        .marginTopM
            margin-top: 20px

        .marginTopL
            margin-top: 30px

        .marginTopXL
            margin-top: 60px

        .marginTopXXL
            margin-top: 120px

        .titleText
            font-size: 30px

        .headerText
            font-size: 24px

        .subHeaderText
            font-size: 20px

        .largeBodyText
            font-size: 18px

        .bodyText
            font-size: 16px

        .storeBadgeContainer
            gap: 30px

        .storeBadge
            height: 50px

        .flow
            display: flex
            justify-content: center
            gap: 30px

        .block33
            width: 33%

        .block40
            width: 40%

        .block50
            flex-grow: 1

        .block50
            flex-grow: 1

        .block60
            width: 60%

        .block66
            width: 66%

        .quote
            padding: 30px

            .text
                font-size: 20px

            .author
                margin-top: 40px
                font-size: 18px

            .role
                font-size: 18px

        .headerTabs

            .headerTab
                width: 150px
                height: 60px

        .objectPictures
            gap: 80px

    @media (min-width: $screen-lg-min)

        .hideOnSmall
            display: flex

        .header
            padding: 20px

        .body
            width: 100vw
            max-width: 100vw
            display: flex
            flex-direction: column
            align-items: center

        .content
            margin-top: 100px
            width: 1000px
            max-width: 1000px
            padding-left: 0px
            padding-right: 0px
            border: none

        .footer
            width: 1000px
            max-width: 1000px
            padding-left: 0px
            padding-right: 0px
            height: 400px

        .logoImage
            width: 60px
            height: 60px

        .marginTopS
            margin-top: 10px

        .marginTopM
            margin-top: 20px

        .marginTopL
            margin-top: 30px

        .marginTopXL
            margin-top: 60px

        .marginTopXXL
            margin-top: 120px

        .titleText
            font-size: 32px

        .headerText
            font-size: 28px

        .subHeaderText
            font-size: 22px

        .largeBodyText
            font-size: 19px

        .bodyText
            font-size: 17px

        .storeBadgeContainer
            gap: 50px

        .storeBadge
            height: 60px

        .flow
            display: flex
            justify-content: center
            gap: 30px

        .block33
            width: 33%

        .block40
            width: 40%

        .block50
            width: 50%

        .block60
            width: 60%

        .block66
            width: 66%

        .headerTabs

            .headerTab
                width: 150px
                height: 60px

        .objectPictures
            gap: 80px

        .customerLogo
            height: 40px


        .headerTabs

            .headerTab
                font-size: 18px

.backgroundColorWhite
    background-color: white

// React Carousel Patches

.react-multiple-carousel__arrow
    background-color: rgba(100, 154, 170, .3)

.react-multiple-carousel__arrow:hover
    background-color: rgba(100, 154, 170, .7)

.react-multi-carousel-dot button
    background-color: rgba(100, 154, 170, 0.3)
    border-color: rgba(100, 154, 170)

.react-multi-carousel-dot--active button
    background-color: rgba(100, 154, 170, 0.7)
    border-color: rgba(100, 154, 170)

// Rolling items

.scrollingBand
    margin: 0 auto
    padding: 20px 0
    max-width: 100%
    overflow: hidden
    display: flex

.group
    display: flex
    align-items: center
    gap: 20px
    padding-right: 20px
    will-change: transform
    animation: scrolling 20s linear infinite

    img
        padding-left: 20px
        padding-right: 20px

@keyframes scrolling
    0%
        transform: translateX(0)

    100%
        transform: translateX(-100%)
