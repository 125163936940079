$primary-color: #8CADCB
$primary-color-dark: #33638F
$primary-color-darkest: #649aaa
$primary-color-light: #33638F
$primary-color-lightest: #8CADCB

$secondary-color-darkest: #444444
$secondary-color-dark: #666666
$secondary-color: #576071
$secondary-color-light: #BBBBBB
$secondary-color-lightest: #DDDDDD

$ternary-color-darkest: #000906
$ternary-color-dark: #00452B
$ternary-color: #0A724B
$ternary-color-light: #2D956E
$ternary-color-lightest: #8ACFB5

$contrast-color-darkest: #00000A
$contrast-color-dark: #090748
$contrast-color: #775DD0
$contrast-color-light: #423E9A
$contrast-color-lightest: #9996D2

$darkgreen: #006400
$red: #FF0000
$darkred: #8B0000
$amber: #FFBF00
$blue: #0000FF

:root
  --primary-color: #{$primary-color}
  --primary-color-light: #{$primary-color-light}
  --primary-color-lightest: #{$primary-color-lightest}
  --primary-color-dark: #{$primary-color-dark}
  --primary-color-darkest: #{$primary-color-darkest}
  --secondary-color: #{$secondary-color}
  --secondary-color-light: #{$secondary-color-light}
  --secondary-color-lightest: #{$secondary-color-lightest}
  --secondary-color-dark: #{$secondary-color-dark}
  --secondary-color-darkest: #{$secondary-color-darkest}
  --ternary-color: #{$ternary-color}
  --ternary-color-light: #{$ternary-color-light}
  --ternary-color-lightest: #{$ternary-color-lightest}
  --ternary-color-dark: #{$ternary-color-dark}
  --ternary-color-darkest: #{$ternary-color-darkest}
  --contrast-color: #{$contrast-color}
  --contrast-color-light: #{$contrast-color-light}
  --contrast-color-lightest: #{$contrast-color-lightest}
  --contrast-color-dark: #{$contrast-color-dark}
  --contrast-color-darkest: #{$contrast-color-darkest}

*
  font-family: "Lato", sans-serif

// Patches

a
  text-decoration: none
  color: var(--primary-color)

a:hover
  cursor: pointer !important

// Design system

.colorWhite
  color: white

.colorGreen
  color: green

.colorAmber
  color: $amber

.colorDarkGreen
  color: $darkred

.colorRed
  color: $red

.colorDarkRed
  color: $darkgreen

.colorBlue
  color: $blue

.colorPurple
  color: purple

.colorGrey
  color: grey

.colorError
  color: $darkred

.colorPrimary
  color: var(--primary-color)

.colorPrimaryLight
  color: var(--primary-color-light)

.colorPrimaryDark
  color: var(--primary-color-dark)

.colorSecondary
  color: var(--secondary-color)

.colorSecondaryLight
  color: var(--secondary-color-light)

.colorSecondaryDark
  color: var(--secondary-color-dark)

.colorContrast
  color: var(--contrast-color)

.colorContrastDark
  color: var(--contrast-color-dark)

.backgroundColorWhite
  background-color: white

.backgroundColorGreen
  background-color: green

.backgroundColorDarkGreen
  background-color: $darkgreen

.backgroundColorRed
  background-color: red

.backgroundColorDarkRed
  background-color: $darkred

.backgroundColorAmber
  background-color: $amber

.backgroundColorBlue
  background-color: $blue

.backgroundColorPurple
  background-color: purple

.backgroundColorLightGrey
  background-color: lightgrey

// Text

.textXXXS
  font-size: 8px

.textXXS
  font-size: 10px

.textXS
  font-size: 12px

.textS
  font-size: 13px

.textM
  font-size: 14px

.textL
  font-size: 18px

.textXL
  font-size: 26px

.textXXL
  font-size: 34px

.textXXXL
  font-size: 50px

.text4XL
  font-size: 70px

.fontWeightBold
  font-weight: bold

.textHeader
  font-size: 18px
  font-weight: bold

.textHeading
  font-size: 18px
  font-weight: bold

.textSubHeading
  font-size: 16px
  font-weight: bold

.textUpperCase
  text-transform: uppercase

// Grid

.width10vw
  width: 10vw

.width20vw
  width: 20vw

.width30vw
  width: 30vw

.width35vw
  width: 35vw

.width40vw
  width: 40vw

.width50vw
  width: 50vw

.width60vw
  width: 60vw

.width70vw
  width: 70vw

.width100vw
  width: 100vw

.width100Pct
  width: 100%

.width10
  width: 10px

.width20
  width: 20px

.width25
  width: 25px

.width30
  width: 30px

.width50
  width: 50px

.width60
  width: 50px

.width70
  width: 60px

.width80
  width: 80px

.width100
  width: 100px

.width150
  width: 150px

.width200
  width: 200px

.width250
  width: 250px

.width300
  width: 300px

.width400
  width: 400px

.width500
  min-width: 500px
  max-width: 500px

.width600
  width: 600px

.width700
  width: 700px

.width800
  width: 800px

.width850
  width: 850px

.width900
  width: 900px

.width950
  width: 950px

.width1000
  width: 1000px

.width1100
  width: 1100px

.width1200
  width: 1200px

.height100vh
  height: 100vh
  max-height: 100vh

.height20
  height: 20px

.height30
  height: 30px

.height40
  height: 40px

.height50
  height: 50px

.height60
  height: 60px

.height80
  height: 80px

.height100
  height: 100px

.height150
  height: 150px

.height200
  height: 200px

.height300
  height: 300px

.height400
  height: 400px

.height500
  height: 500px

.minHeight600
  min-height: 600px

.height600
  height: 600px

.height700
  height: 700px

.displayBlock
  display: block

.flex
  display: flex

.flex1
  flex: 1

.displayFlex
  display: flex

.flexGrow1
  flex-grow: 1

.flexWrap
  flex-wrap: wrap

.flexDirectionColumn
  flex-direction: column

.justifyContentStart
  justify-content: flex-start

.justifyContentCenter
  justify-content: center

.justifyContentEnd
  justify-content: flex-end

.alignItemsStart
  align-items: start

.alignItemsCenter
  align-items: center

.alignItemsEnd
  align-items: end

.alignItemsBaseline
  align-items: baseline

.alignItemsStretch
  align-items: stretch

.flexWrap
  flex-wrap: wrap

.paddingXS
  padding: 5px

.paddingS
  padding: 12px

.paddingM
  padding: 20px

.paddingL
  padding: 25px

.paddingXL
  padding: 40px

.paddingXXL
  padding: 60px

.paddingBottomM
  padding: 20px

.marginLeftXS
  margin-left: 3px

.marginLeftS
  margin-left: 7px

.marginLeftM
  margin-left: 20px

.marginLeftL
  margin-left: 25px

.marginLeftXL
  margin-left: 40px

.marginLeftXXL
  margin-left: 60px

.marginLeftXXXL
  margin-left: 100px

.marginTopL
  margin-top: 25px

.marginTopXL
  margin-top: 40px

.marginTopXXL
  margin-top: 60px

.marginTopXXXL
  margin-top: 100px

.marginTop3XL
  margin-top: 100px

.marginTop4XL
  margin-top: 130px

.marginTopXXS
  margin-top: 3px

.marginTopXS
  margin-top: 7px

.marginTopS
  margin-top: 12px

.marginTopM
  margin-top: 20px

.marginTopL
  margin-top: 25px

.marginRightXS
  margin-right: 3px

.marginRightS
  margin-right: 7px

.marginRightM
  margin-right: 15px

.marginRightL
  margin-right: 25px

.marginRightXL
  margin-right: 40px

.marginBottomXS
  margin-bottom: 3px

.marginBottomS
  margin-bottom: 7px

.marginBottomM
  margin-bottom: 15px

.marginBottomL
  margin-bottom: 25px

.marginBottomXL
  margin-bottom: 40px

.marginBottomXXL
  margin-bottom: 60px

.marginBottomXXXL
  margin-bottom: 100px

.gapXS
  gap: 3px

.gapS
  gap: 7px

.gapM
  gap: 20px

.gapL
  gap: 25px

.gapXL
  gap: 32px

.textAlignLeft
  text-align: left

.textAlignRight
  text-align: right

.textAlignJustify
  text-align: justify

.positionRelative
  position: relative !important

.positionAbsolute
  position: absolute

.positionFixed
  position: fixed

.displayNone
  display: none

.overflowYScroll
  overflow-y: scroll

.borderNeutral
  border: 1px solid #E1E1E1

.borderLeftNeutral
  border-left: 1px solid #E1E1E1

.borderRightNeutral
  border-right: 1px solid #E1E1E1

.borderBottomNeutral
  border-bottom: 1px solid #E1E1E1

.borderNeutralDashed
  border: 1px dashed #E1E1E1

// Events

.hoverHand:hover
  cursor: pointer

.zIndex900
  z-index: 900

.zIndex999
  z-index: 999

.textAlignStart
  text-align: start

.textAlignCenter
  text-align: start

.textAlignEnd
  text-align: end

.overflowWrapBreakWord
  overflow-wrap: break-word

.overflowWrapWordWrap
  overflow-wrap: word-wrap !important
  white-space: normal

.textOverflowEllipsis
  text-overflow: ellipsis

.textOrientationUp
  text-orientation: mixed
  writing-mode: vertical-lr

.zIndex999
  z-index: 999

// Components

.headerM
  font-size: 14px
  font-weight: bold
  color: var(--primary-color-dark)

.headerL
  font-size: 18px
  font-weight: bold
  color: var(--primary-color-dark)

.headerXL
  font-size: 26px
  font-weight: bold
  color: var(--primary-color-dark)

.headerXXL
  font-size: 34px
  font-weight: bold
  color: var(--primary-color-dark)

.headerXXXL
  font-size: 50px
  font-weight: bold
  color: var(--primary-color-dark)

.dangerButton
  background-color: 'red' !important
  border-color: 'red' !important

.entryPanel
  width: 600px

.popupOverlay
  background: rgba(0, 0, 0, 0)
  bottom: 0
  left: 0
  position: fixed
  right: 0
  top: 0

.popupContent
  background-color: "white" !important
  left: -20px
  max-width: 400px
  padding: 20px
  position: absolute
  top: 5px
  z-index: 999

  .hidden
    display: none

.section
  box-sizing: border-box
  position: relative
  padding: 15px
  // border: 1px solid #EFEFEF
  border-radius: 4px
  //min-height: 200px
  display: flex
  background-color: white
  flex-direction: column

  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px

  .title
    //position: absolute
    //padding: 5px
    // top: -15px
    // left: 20px
    background-color: transparent
    // z-index: 999
    font-weight: bold,
    color: #888

// DnD Area

.dndArea
  min-width: 200px
  min-height: 100px
  border: 1px dashed lightgrey
  background-color: #F8F8F8
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: 10px
  margin: 5px

  .instructions

.dndArea:hover
  background-color: #EFEFEF

// File upload

.fileUpload
  .dropZone
    min-width: 200px
    min-height: 100px
    border: 1px dashed lightgrey
    background-color: #F8F8F8
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    &.dragOver
      background-color: var(--secondary-color-lightest)
      color: white

    input[type="file"]
      display: none

    .customFileInput
      border: 1px solid #ccc
      display: inline-block
      padding: 6px 12px
      cursor: pointer

.documentSlot
  .dropZone
    border: 1px dashed lightgrey
    background-color: #EFEFEF
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    &.dragOver
      background-color: var(--secondary-color-lightest)
      color: white

    input[type="file"]
      display: none

    .customFileInput
      border: 1px solid #ccc
      display: inline-block
      padding: 6px 12px
      cursor: pointer

// Map

.mapContainer
  position: relative
  border: 1px solid lightgrey

  .legend
    position: absolute
    z-index: 999
    background-color: white
    border: 1px solid lightgrey
    left: 30px
    bottom: 30px
    padding: 10px

    .title
      font-weight: 600

    .valueRanges
      display: flex
      gap: 10px
      margin-top: 10px

      .valueRange
        display: flex
        flex-direction: column
        align-items: center
        width: 50px

        .colorBox
          width: 20px
          height: 20px

        .rangeLimits

// Portal header

.portalHeader
  background: rgba(255, 255, 255, 0.9)
  padding: 10px
  display: flex
  align-items: center

  .title
    flex-grow: 1
    display: flex
    justify-content: center

  .logoImage
    height: 60px

// OTP

.otp
  z-index: 100

  input
    width: 30px
    height: 30px
    padding: 0
    font-size: 24px
    text-align: center
    margin-right: 12px
    text-transform: uppercase
    color: #494949
    border: 1px solid #d6d6d6
    border-radius: 4px
    background: #fff
    background-clip: padding-box

// Table

table.simple
  border: 2px solid #000000
  width: 100%
  text-align: left
  border-collapse: collapse

table.simple td, table.simple th
  border: 1px solid #000000
  padding: 5px 4px

table.simple tbody td
  font-size: 13px

table.simple thead
  background: #CFCFCF
  background: -moz-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%)
  background: -webkit-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%)
  background: linear-gradient(to bottom, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%)
  border-bottom: 2px solid #000000

table.simple thead th
  font-size: 15px
  font-weight: bold
  color: #000000
  text-align: left

table.simple tfoot
  font-size: 14px
  font-weight: bold
  color: #000000
  border-top: 2px solid #000000

table.simple tfoot td
  font-size: 14px

// Settings Page

.logoUpload
  display: flex
  flex-direction: column

  .buttons
    display: flex
    gap: 10px

  .imageArea
    img
      width: 300px
      height: auto

// Microsoft patches

.ms-TextField-fieldGroup
  border: 1px solid #DDDDDD

.underlined
  .ms-TextField-fieldGroup
    border: none
    border-bottom: 1px solid #DDDDDD !important

.ms-TextField-fieldGroup:focus
  border: 1px solid red

// Chatbot

.chatBot
  display: flex
  flex-direction: column
  align-items: center

  .messagesContainer
    height: 70vh
    max-height: 70vh
    overflow-y: scroll
    display: flex
    flex-direction: column
    align-items: stretch
    text-align: left
    font-size: 16px

  @media only screen and (min-width: 1000px)
    .chatContent
      width: 1000px
      height: calc(100vh - 140px)
      display: flex
      flex-direction: column
      align-items: stretch

      .messagesContainer
        flex-grow: 1

        .messages
          overflow-y: scroll

      .logo
        height: 400px
        display: flex
        justify-content: center
        align-items: center

        img
          width: 200px
          height: auto

      .suggestions
        margin-top: 100px
        margin-bottom: 100px
        display: flex
        align-items: center
        justify-content: center
        gap: 10px

        .suggestion
          border: 1px solid #eeeeee
          border-radius: 5px
          padding: 20px
          min-width: 250px
          max-width: 250px
          min-height: 100px
          max-height: 100px
          display: flex
          flex-direction: column
          align-items: start
          justify-content: start
          text-align: left

          .request
            margin-top: 10px

        .suggestion:hover
          background-color: #efefef
          border: 1px solid #dddddd
          cursor: pointer

      .inputContainer
        padding-top: 20px
        flex-grow: 0

.spinner-box
  flex-grow: 1
  height: 20px
  display: flex
  justify-content: center
  align-items: center
  background-color: transparent

.pulse-container
  width: 50px
  display: flex
  justify-content: space-between
  align-items: center

.pulse-bubble
  width: 10px
  height: 10px
  border-radius: 50%
  background-color: lightgrey

.pulse-bubble-1
  animation: roll 1.4s ease-in-out infinite

.pulse-bubble-2
  animation: roll 1.4s ease-in-out infinite
  animation-delay: 0.2s

.pulse-bubble-3
  animation: roll 1.4s ease-in-out infinite
  animation-delay: 0.4s

@keyframes roll
  0%
    transform: translateY(0) scale(1)
    opacity: 0.5

  50%
    transform: translateY(-5px) scale(1.2)
    opacity: 1

  100%
    transform: translateY(0) scale(1)
    opacity: 0.5

.resource
  padding: 7px
  background-color: white
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px
  margin-bottom: 10px

.resource .title
  font-weight: bold

.resource .link
  font-weight: bold

.inputControls
  width: 40px

.mic
  position: relative
  display: inline-flex
  justify-content: center
  align-items: center
  width: 30px
  height: 30px
  background-color: transparent
  color: #fff
  font-size: 33px
  border: none
  border-radius: 50%
  cursor: pointer
  transition: background-color 0.25s
  z-index: 15
  margin-right: 10px

.mic::before
  content: ""
  position: absolute
  width: inherit
  height: inherit
  border-radius: inherit
  background-color: inherit
  z-index: 10

.icon
  width: 20px

.listening
  background-color: rgb(0, 120, 212)

.settingsPage

  .companyPanel

    .imageArea
      max-height: 100px
      min-height: 100px

      .companyLogo
        height: 100px

.markup
  width: 900px

  h1
    color: grey
    font-size: 24px !important

  h2
    color: var(--primary-color)
    font-size: 20px !important

// Progress spinner

.progress
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100vh

// Message box

.messageBox
  position: absolute
  top: 10px
  left: 10px
  right: 10px
  z-index: 999
  display: flex
  flex-direction: column

  .message
    height: 40px
    background-color: var(--contrast-color-light)
    color: white
    margin-bottom: 3px

// License selection

.plan
  z-index: 900
  width: 200px
  border: 1px solid var(--secondary-color)
  background-color: var(--secondary-color-lightest)

  &.selected
    background-color: var(--secondary-color)

    .planSelected
      color: white

  &.selected:hover
    background-color: var(--secondary-color)

.plan:hover
  cursor: pointer
  background-color: var(--secondary-color-light)

.labelValueList
  display: block

  .labelValue
    display: flex
    margin-bottom: 10px

    .label
      min-width: 150px
      font-weight: bold

    .value

.qrRenderer
  position: fixed
  top: 0
  left: 0
  visibility: hidden

.bodyPanel
  width: 100vw
  display: flex

.sideBar
  min-width: 250px
  max-width: 250px
  min-height: calc(100vh - 80px)
  max-height: calc(100vh - 80px)

  .sideBarItem
    padding: 0px
    padding-left: 15px

  .sideBarItem:hover
    cursor: pointer

.contentPanel
  flex: 1
  min-height: calc(100vh - 80px)
  max-height: calc(100vh - 80px)

.pulsing
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1)
  transform: scale(1)
  animation: pulse 2s infinite

@keyframes pulse
  0%
    transform: scale(0.95)
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7)

  70%
    transform: scale(1)
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0)

  100%
    transform: scale(0.95)
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)

  // Datable patches

.rdt_TableHeadRow
  font-weight: bold
  font-size: 14px

.rdt_TableHeader
  font-weight: bold

.rdt_Table > .rdt_TableCol
  padding-left: 0px !important

.rdt_Table > .rdt_TableCol ~ .rdt_TableCol
  padding-left: 8px !important
  padding-right: 8px !important

.rdt_Table > .rdt_TableCell
  padding-left: 0px !important

.rdt_Table > .rdt_TableCell ~ .rdt_TableCell
  padding-left: 8px !important
  padding-right: 8px !important

.rdt_TableRow
  background-color: white

.rdt_TableRow:nth-child(even)
  background-color: #F9F9F9

// Table Tree

.cp_tree-table
  font-size: 14px
  color: #040402
  background: #fff
  border: 1px solid #E1E1E1
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif

/* Header */

.cp_tree-table .cp_tree-table_header
  color: black
  font-weight: bold
  background: white
  border-bottom: 1px solid #E1E1E1
  height: 50px !important

.cp_tree-table .cp_tree-table_header-cell>.t-right
  text-align: right

.cp_tree-table .cp_tree-table_cell,
.cp_tree-table .cp_tree-table_header-cell
  display: flex
  align-items: center
  padding: 0 10px

.cp_tree-table .cp_tree-table_cell .toggle-button
  position: relative
  display: inline-block
  border: none
  height: 14px
  vertical-align: middle
  padding: 0 5px 0 0
  margin: 0
  cursor: pointer
  background-color: transparent
  outline: none

.cp_tree-table .cp_tree-table_cell .toggle-button::after
  content: ''
  display: inline-block
  width: 0
  height: 0
  border-style: solid
  border-width: 5px 5px 0 5px
  border-color: #040402 transparent transparent transparent
  padding: 0
  vertical-align: middle

.cp_tree-table .cp_tree-table_cell .toggle-button+span
  vertical-align: middle

.cp_tree-table .cp_tree-table_cell>.without-children>span
  padding-left: 15px

.cp_tree-table .cp_tree-table_cell>.with-children>span
  font-weight: 600

// Tree styling

.tree
  list-style: none
  margin: 0
  padding: 20px

  .tree-node,
  .tree-node-group
    list-style: none
    margin: 0
    padding: 0

  .tree-branch-wrapper,
  .tree-node__leaf
    outline: none

  .tree-node--focused
    outline-color: rgb(77, 144, 254)
    outline-style: auto
    outline-width: 2px
    display: block

  .tree-node__branch
    display: block

  .tree-node
    cursor: pointer

// Markers

.marker
  width: 0
  height: 0

.marker  span
  display: flex
  justify-content: center
  align-items: center
  box-sizing: border-box
  width: 30px
  height: 30px
  color: #fff
  background: rgba(255,255,255,0.9)
  border: solid 1px green
  border-radius: 0 70% 70%
  box-shadow: 0 0 2px #000
  cursor: pointer
  transform-origin: 0 0
  transform: rotateZ(-135deg)

.marker b
  transform: rotateZ(135deg)


// Markdown

.markdown
  a
    color: var(--primary-color-dark)


